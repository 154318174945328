import RenderMenuItem from '../../components/RenderMenuItem';
import React, {useEffect, useState} from 'react';
import OrderSteps from '../../components/OrderSteps';
import {useData} from '../../state/data';

function Step1({ onBackStep, onNextStep, menuId, onSetMenuId }) {

	const { state: { data } } = useData();
	const { menu, labels } = data;

	return (
		<div className="OrderStep">
			<div className="OrderStep_Top">
				<h1>{labels.componi_piatto}</h1>
				<OrderSteps value={1} />
			</div>
			<div className="OrderStep_Inner">
				<p>{labels.order_step_1}</p>
				{menu.map((menuItem) => (
					<div className="MenuOption" key={`menu-${menuItem.id}`}>
						<input id={`item-${menuItem.id}`} type="radio" name="portate" checked={menuId === menuItem.id} onChange={() => onSetMenuId(menuItem.id)} />
						<label htmlFor={`item-${menuItem.id}`}>
							<RenderMenuItem item={{name: menuItem.name, price: menuItem.price, description: menuItem.description}} type="menu" />
						</label>
					</div>
				))}
			</div>
			<div className="OrderStep_Buttons">
				<button className="ButtonLink" onClick={onBackStep}>{labels.back}</button>
				<button className="Button" type="button" onClick={onNextStep} disabled={!menuId}>{labels.next}</button>
			</div>
		</div>
	);

}

export default Step1;
