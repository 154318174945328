import React, {useContext} from 'react';
import {MenuContext} from '../contexts';
import RenderMenuItem from '../components/RenderMenuItem';
import {useData} from '../state/data';

function ChefSuggestions() {

	const { state: { data } } = useData();
	const { chefSuggestions, labels, isLounchWorkDays } = data;


	return (
		<div className="Page">
			<h1>Proposte dello Chef</h1>
			{!isLounchWorkDays ? <div style={{ marginBottom: 15 }}><small>{labels.coperto}</small></div> : null}
			{chefSuggestions.map(item => <RenderMenuItem item={item} type="chef-suggestion" key={`cicchetto-${item.id}`} />)}
		</div>
	);

}

export default ChefSuggestions;
